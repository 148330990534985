$(function () {

    $('.loader-list').show();

    $('.wine-results .item-content').matchHeight();

    // var resizeEvt;

    if ($('img[usemap]') && $('img[usemap]').length > 0) {
        // https://github.com/kemayo/maphilight
        // NB: non installato con Bower ma utlizzato come file in core/js/

        // questo non ha funzionato
        // https://github.com/stowball/jQuery-rwdImageMaps
        // https://permanenttourist.ch/2017/02/responsive-imagemap-with-interactive-highlighting-function/

        // questo si
        // https://gist.github.com/stowball/5299593
        $('img[usemap]').rwdImageMaps({'useMaphilight': true});
    }

    // $(window).on('resize.usemaps', function(){
    //   clearTimeout(resizeEvt);
    //   resizeEvt = setTimeout(function(){
    //       // $('img[usemap]').maphilight();
    //   }, 200);
    //
    // });

    $(window).on('load', function (e) {
        $('.results__list').show();
        $('.wine-results__list').show();
        $('.loader-list').hide();

        loadImageProgress();

    });

    function loadImageProgress() {

        $('.results .item-content').each(function (i, e) {
            // carico i post uno alla volta
            $(this).delay(100 * i).css({opacity: 0}).animate({opacity: 1}, 500, function () {
            });

        });

        $('.wine-results .item-content').each(function (i, e) {
            // carico i post uno alla volta
            $(this).delay(100 * i).css({opacity: 0}).animate({opacity: 1}, 500, function () {
            });

        });

        loadRelated();

        $('.wine-results .item-content').matchHeight();
    }

    function loadRelated() {
        $('.related .item-content').each(function (i, e) {
            // carico i post uno alla volta
            $(this).delay(100 * i).css({opacity: 0}).animate({opacity: 1}, 500, function () {
            });

        });
    }

    // open modal with product detail
    $(document).on('open.zf.reveal', '.modal-typical-prod[data-reveal]', function (e) {
        var target = $(e.target);
        requestTypicalProducts(target);
    });

    // load detail url via ajax and track ga modal page
    function requestTypicalProducts(target) {
        var url = target.data('ajax');
        var ajaxUrl = url + '?mode=modal';

        $.ajax(ajaxUrl).done(function (resp) {
            var content = $(resp).html();
            target.find('.modal-content').html(content).foundation();
        });

        trackModal(url);
    }

    // DOC DOCG WINES
    $(document).on('open.zf.reveal', '.modal-wine[data-reveal]', function (e) {
        var target = $(e.target);
        requestWines(target);
    });

    // load detail url via ajax and track ga modal page
    function requestWines(target) {
        var url = target.data('ajax');
        var ajaxUrl = url + '?mode=modal';

        $.ajax(ajaxUrl).done(function (resp) {
            var content = $(resp).html();
            target.find('.modal-content').html(content).foundation();

            loadRelated();
        });

        trackModal(url);
    }


    // SEARCH AND PAGING
    // click on production structure search submit
    // show loader, hide list and post search parameter data
    $(document).on('click', '.typical-products button[type="submit"]', function (e) {
        e.preventDefault();
        var url = location.pathname;

        $('input[name=page]').val('1');
        var data = $('form[name=frm_search]').serialize();

        postTypicalProductData(url, data);
    });

    $(document).on('click', '.typical-products a[data-paginator]', function (e) {
        e.preventDefault();

        var next_page = $(this).data('page');
        var page_field = $('input[name=page]');

        if (next_page !== '') {
            page_field.val(next_page);
        }

        var url = location.pathname;
        var data = $('form[name=frm_search]').serialize();

        postTypicalProductData(url, data);

    });

    $(document).on('click', '.typical-products .button[data-search-reset]', function (e) {
        e.preventDefault();
        var url = location.pathname;
        var data = $("input[name=csrfmiddlewaretoken]").serialize();
        postTypicalProductData(url, data);
    });

    // Typical Products Searches
    function postTypicalProductData(url, data) {
        $('.loader-list').show();
        var listWrapper = $('.typical-products .results__list');
        listWrapper.hide();
        var formWrapper = $('.typical-products .results__search');
        var paginatorWrapper = $('.typical-products .results__paginator');

        $.post(url, data, function (data) {

            listWrapper.html(data['typical_products']);
            formWrapper.html(data['search_form']);
            paginatorWrapper.html(data['paginator']);

            listWrapper.foundation();
            paginatorWrapper.foundation();

            setTimeout(function (e) {
                $('.loader-list').hide();
                listWrapper.show();
                loadImageProgress();
            }, 1200);
        });
    }

    // WINES
    $(document).on('click', '.wines button[type="submit"]', function (e) {
        e.preventDefault();
        var url = location.pathname;

        $('input[name=page]').val('1');
        var data = $('form[name=frm_search]').serialize();

        postWinesData(url, data);
    });

    $(document).on('click', '.wines a[data-paginator]', function (e) {
        e.preventDefault();

        var next_page = $(this).data('page');
        var page_field = $('input[name=page]');

        if (next_page !== '') {
            page_field.val(next_page);
        }

        var url = location.pathname;
        var data = $('form[name=frm_search]').serialize();

        postWinesData(url, data);

    });

    $(document).on('click', '.wines .button[data-search-reset]', function (e) {
        e.preventDefault();
        var url = location.pathname;
        var data = $("input[name=csrfmiddlewaretoken]").serialize();
        postWinesData(url, data);
    });

    // Wines Searches
    function postWinesData(url, data) {
        $('.loader-list').show();
        var listWrapper = $('.wines .wine-results__list');
        listWrapper.hide();
        var formWrapper = $('.wines .wine-results__search');
        var paginatorWrapper = $('.wines .wine-results__paginator');

        $.post(url, data, function (data) {

            listWrapper.html(data['wines']);
            formWrapper.html(data['search_form']);
            paginatorWrapper.html(data['paginator']);

            listWrapper.foundation();
            paginatorWrapper.foundation();

            setTimeout(function (e) {
                $('.loader-list').hide();
                listWrapper.show();
                loadImageProgress();
            }, 1200);

        });
    }
});