$(function () {
  $('.contacts #bt-send').on('click', function (e) {
    e.preventDefault();
    $(this).prop("disabled", true);
    $('form[name=frm-contact]').submit();
  });

  $('.contacts select').on('change', function (e) {
    if ($(this).val() == ''){
      $(this).removeClass('font-helvetica');
    }else{
      $(this).addClass('font-helvetica');
    }

  });

  $('.row-form .medium-6').matchHeight();

  $('[data-option-request]').on('click', function (e) {
    e.preventDefault();
    var opt = $(this).data('option-request');
    var radio = $('#id_request_type_' + opt).prop('checked', true);

    $('.selected-request').text(radio.parent().text());

    $('.pre-form-content').hide();
    $('.form-content').fadeIn();
  });
});