$(function () {

    // SEARCH AND PAGING
    // click on production structure search submit
    // show loader, hide list and post search parameter data
    $(document).on('click', '.ambassadors-index button[type="submit"]', function (e) {
        e.preventDefault();
        var url = location.pathname;

        $('input[name=page]').val('1');
        var data = $('form[name=frm_search]').serialize();

        postAmbassadorsData(url, data);
    });

    $(document).on('click', '.ambassadors .button[data-search-reset]', function (e) {
        e.preventDefault();
        var url = location.pathname;
        var data = $("input[name=csrfmiddlewaretoken]").serialize();
        // $('form[name="frm_search"]').submit();
        postAmbassadorsData(url, data);
    });


    // Typical Products Searches
    function postAmbassadorsData(url, data) {
        console.log('ambassadors post');
        $('.loader-list').show();
        var listWrapper = $('.ambassadors-index .ambassadors__list');
        listWrapper.hide();
        var formWrapper = $('.ambassadors-index .results__search');
        // var paginatorWrapper = $('.ambassadors-index .results__paginator');

        $.post(url, data, function (data) {

            listWrapper.html(data['ambassadors']);
            formWrapper.html(data['search_form']);
            // paginatorWrapper.html(data['paginator']);

            listWrapper.foundation();
            // paginatorWrapper.foundation();

            setTimeout(function (e) {
                $('.loader-list').hide();
                listWrapper.show();
                // loadImageProgress();
            }, 1200);
        });
    }

});