$(function () {

  // $('.find-solution .prod-item').matchHeight();

  $(window).on('load', function(e) {
    $('.structures .results__list').show();
    $('.loader-list').hide();

    loadImageProgress();

  });

  function loadImageProgress() {
    $('.structures .item-content').each(function(i, e) {
      // carico i post uno alla volta
      $(this).delay(100*i).css({opacity:0}).animate({ opacity: 1 }, 500, function() {});

    });
  }

  // open modal with product detail
  $(document).on('open.zf.reveal', '.modal-prod-structure[data-reveal]', function (e) {
    var target = $(e.target);
    requestStructures(target);
  });

  // load detail url via ajax and track ga modal page
  function requestStructures(target) {
    var url = target.data('ajax');
    var ajaxUrl = url + '?mode=modal';

    $.ajax(ajaxUrl).done(function (resp) {
      var content = $(resp).html();
      target.find('.modal-content').html(content).foundation();
    });

    trackModal(url);
  }

  // SEARCH AND PAGING
   // click on production structure search submit
  // show loader, hide list and post search parameter data
  $(document).on('click', '.structures button[type="submit"]', function(e) {
    e.preventDefault();
    var url = location.pathname;

    // var post_url = $('.post-url').val();
    // if (post_url && post_url !== '') {
    //   url = post_url;
    //   console.log(url);
    // }

    $('input[name=page]').val('1');
    var data = $('form[name=frm_search]').serialize();

    postStructureData(url, data);
  });

  $(document).on('click', '.structures .button[data-search-reset]', function (e) {
    e.preventDefault();
    var url = location.pathname;
    var data = $("input[name=csrfmiddlewaretoken]").serialize();
    postStructureData(url, data);
  });

  $(document).on('click', '.structures a[data-paginator]', function (e) {
    e.preventDefault();

    var next_page = $(this).data('page');
    var page_field = $('input[name=page]');

    if (next_page !== '') {
      page_field.val(next_page);
    }

    var url = location.pathname;
    var data = $('form[name=frm_search]').serialize();

    postStructureData(url, data);

  });

  // Structures Searches
  function postStructureData(url, data) {
    $('.loader-list').show();
    var listWrapper =  $('.structures .results__list');
    listWrapper.hide();
    var formWrapper =  $('.structures .results__search');
    var paginatorWrapper =  $('.structures .results__paginator');

    $.post(url, data, function (data) {

      listWrapper.html(data['structures']);
      formWrapper.html(data['search_form']);
      paginatorWrapper.html(data['paginator']);

      listWrapper.foundation();
      paginatorWrapper.foundation();
      console.log('timeout');
      setTimeout(function(e) {
        $('.loader-list').hide();
        listWrapper.show();
        loadImageProgress();
      }, 1200);
    });
  }
});