$(function () {

  // $('select#id_country').on('change', function (e) {
  //
  //   var $countryField = $(this);
  //   var $provinceField = $('select#id_province');
  //
  //   var url = $countryField.data('provinces-url');
  //   var countryId = $countryField.val();
  //
  //   if (countryId) {
  //     var ajaxUrl = url;
  //     $.get(ajaxUrl, {'country': countryId}, function (data) {
  //       $provinceField.empty();
  //
  //       var provinces = data['options'];
  //       $.each(provinces, function (index, option) {
  //         var $option = $("<option></option>")
  //           .attr("value", option.value)
  //           .text(option.text);
  //         $provinceField.append($option);
  //       });
  //
  //       if (provinces.length > 1) {
  //         $provinceField.removeAttr('disabled');
  //       } else {
  //         $provinceField.attr('disabled', 'disabled');
  //       }
  //     });
  //   }
  // });
});