$(function () {
    if (typeof monitorMessagesUrl !== 'undefined') {

        function doPoll() {
            var $messagesContainer = $('#sync-log-messages-container');
            var $overviewContainer = $('#session-overview-container');


            var openMessagesIds = $('#sync-log-messages-container li').not('.close').map(
                function () {
                    return $(this).data('message-id');
                }
            );

            $.get(monitorMessagesUrl,
                {open: openMessagesIds.toArray().join(',')},
                function (data) {
                    $messagesContainer.html(data.messages_list);
                    $overviewContainer.html(data.session_overview);
                    setTimeout(doPoll, 1000);
                });

        }

        doPoll();
    }
});
