$(document).foundation();



$(function () {
  var menuLangsIsOpen = false;
  var prevMega;

  $('.box-series .box-logo').matchHeight();
  $('.box-series .text-w__box').matchHeight();
  $('.page-content--middle .series-buttons .text-content').matchHeight();

  // MODAL
  // open modal reveal
  $(document).on('click', 'a[data-open]', function (e) {
    e.preventDefault();
  });

  // reveal modal from medium up
  $(document).on('click', 'a[data-eer-open]', function (e) {
    if (Foundation.MediaQuery.atLeast('medium')) {
      e.preventDefault();
      var el = $(this).data('eer-open');
      $('#' + el).foundation('open');
    }
  });


  // MENU
  //-----------------------------------------------------------
  //MAIN MENU
  var preselectedEl = $('.nav--primary > li.current');
  $('.nav--primary > li > a').on('click', function (e) {
    var el = $(this);

    closeAllMenues('menuMegas');

    if (prevMega && !prevMega.is(el)) {
      if (prevMega.next().hasClass('items-menu')) {  // dd-wrapper')) {  // items-menu')) {
        prevMega.next().removeClass('opened');
      }
    }

    $('.nav--primary > li').removeClass('current');
    preselectedEl.addClass('current');
    el.parent().addClass('current');

    if (el.next().hasClass('items-menu')) {  // 'dd-wrapper')) {  // items-menu')) {
      e.preventDefault();
      el.next().toggleClass('opened');
    }

    if (el.next().hasClass('mega-wrapper')) {
      if (el.next().hasClass('opened')) {
        $('#overlay_mm').addClass('is-active');
      } else {
        $('#overlay_mm').removeClass('is-active');
      }
    }

    prevMega = el;
  });

  //close all menus when click on body
  $(document.body).on('click', function (e) {
    if (!$(e.target).parents('header').length) {
      closeAllMenues();
    }
  });

  $(document).on('hashpressed', function (e, sender) {
    console.log('hashpressed');
    closeMegas();

    // close mobile overlay
    if ($('#overlay').hasClass('is-active')) {
      $('#overlay').foundation('toggle');
    }

    // close mobile panel
    if ($('#small-menu-panel').hasClass('is-active')) {
      $('#small-menu-panel').foundation('toggle');
    }
  });
  //-----------------------------------------------------------
  // LANGS MENU
  $('.selected-lang').on('click', function (e) {
    e.preventDefault();

    closeAllMenues('menuLangs');

    if (!menuLangsIsOpen) {
      $(this).addClass('active');
      $('.langs__list').addClass('on');

      menuLangsIsOpen = true;
    } else {
      closeMenuLangs();
    }

  });


  //-----------------------------------------------------------
  //Closer
  function closeMenuLangs() {
    //close menu langs
    $('.selected-lang').removeClass('current');
    $('.langs__list').removeClass('on');

    menuLangsIsOpen = false;
  }
  function closeMegas() {
    $('.nav--primary > li').removeClass('current');
    preselectedEl.addClass('current');
    $('.items-menu').removeClass('opened');

    prevMega = undefined;
  }

  function closeAllMenues(except) {
    $('#overlay_mm').removeClass('is-active');

    if (except !== 'menuMegas') {
      closeMegas();
    }
    if (except !== 'menuLangs') {
      closeMenuLangs();
    }
  }
  //-----------------------------------------------------------


  // CMS modal
  // open modal with product detail
  $(document).on('open.zf.reveal', '.modal-cms[data-reveal]', function (e) {
      var target = $(e.target);
      requestCMSPage(target);
  });

  // load detail url via ajax and track ga modal page
  function requestCMSPage(target) {
      var url = target.data('ajax');
      var ajaxUrl = url + '?mode=modal';

      $.ajax(ajaxUrl).done(function (resp) {
        var content = $(resp).find('.page-content').html();
        console.log(content);
        target.find('.modal-content').html(content).foundation();
      });

      trackModal(url);
  }
});

//reinit addthis after ajax call
function reInitAddThis() {
  addthis.toolbox('.addthis_toolbox')
}


// TRACKING GA
function trackModal(url) {
  // console.log('u:' + url);
  ga('set', 'page', url);
  ga('send', 'pageview');
}
