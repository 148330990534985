$(function () {

    $('.news-index .article__title').matchHeight();
    $('.event .event__title').matchHeight();

    if ($('.latest-news').length) {
        $('.latest-news .article__title').matchHeight();
    }

    var $gallery_slider = $('.slider__gallery .slides');

    $(document).on('change', '.news-index .search_form select', function (e) {
        e.preventDefault();
        var url = location.pathname;

        // $('input[name=page]').val('1');
        var data = $('form[name=frm-news-search]').serialize();

        postNewsData(url, data);
    });


    function postNewsData(url, data) {
        $('.loader-list').show();
        var listWrapper = $('.news-index__list');
        listWrapper.hide();
        var formWrapper = $('.news-index__filter');
        // var paginatorWrapper =  $('.typical-products .results__paginator');
        temp = url.split('/');

        state = categories_urls[$('#id_category').val()];

        console.log(state)
        window.history.pushState('News', 'News', state);

        $.post(url, data, function (data) {
            listWrapper.html(data['articles']);
            formWrapper.html(data['form']);

            // paginatorWrapper.html(data['paginator']);

            listWrapper.foundation();
            // paginatorWrapper.foundation();

            setTimeout(function (e) {
                $('.loader-list').hide();
                listWrapper.show();
                // loadImageProgress();
            }, 1200);


        });
    }

    $(document).on('open.zf.reveal', '.modal-news[data-reveal]', function (e) {
        var target = $(e.target);
        requestNews(target);
    });

    // load detail url via ajax and track ga modal page
    function requestNews(target) {
        var url = target.data('ajax');
        var ajaxUrl = url + '?mode=modal';

        $.ajax(ajaxUrl).done(function (resp) {
            // target.html(resp).foundation();
            var content = $(resp).html();
            target.find('.modal-content').html(content).foundation();

            // initSimpleSlider();
            reInitAddThis();
            initNewsLightCase();
        });

        trackModal(url);
    }

    function initSimpleSlider() {
        $gallery_slider = $('.slider__gallery .slides');
        $gallery_slider.slick({
            'dots': true,
            'arrows': false,
            'autoplay': true,
            'fade': false,
            'autoplaySpeed': 4000,
            'speed': 300,
            'slidesToShow': 4,
            'slidesToScroll': 4,
            'infinite': true,
            'pauseOnHover': true,
            'adaptiveHeight': true
        });

        // On before slide change
        $gallery_slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('[data-slider-gallery] [data-slide-' + currentSlide + '] .animate').toggleClass('on');
            $('[data-slider-gallery] [data-slide-' + nextSlide + '] .animate').toggleClass('on');
        });
    }

    //initSimpleSlider();

});

function initNewsLightCase() {
    $('a[data-rel="lightcase:news:slideshow"]').lightcase({
        transition: 'scrollHorizontal',
        maxWidth: 1280,
        maxHeight: 840,
        shrinkFactor: 1
    });
}

$(window).on('load', function () {
    $('[data-slider-gallery] [data-slide-0] .animate').addClass('on');
});